import React, { useEffect, useState } from "react";
import StockInLayout from "../../../components/ui/Inventory/StockIn/StockInLayout";
import { useLocation } from "react-router-dom";
import { getEachInventoryItems } from "../../../api/Inventory/dashboard/dashboard";
import { getAllSuppliers } from "../../../api/Inventory/suppliers/suppliers";

//

const StockInPage = () => {
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [singleStockInData, setSingleStockInData] = useState({});
  const [searchSupplier, setSearchSupplier] = useState("");
  const location = useLocation();
  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const getAllSuppliersData = (searchSupplier) => {
    setLoading(true);
    getAllSuppliers(searchSupplier)
      .then((response) => {
        setSuppliers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStockInData = () => {
    setLoading(true);
    getEachInventoryItems(id)
      .then((response) => {
        setSingleStockInData(response.data.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getStockInData();
    // getAllSuppliersData();
  }, []);

  const fetchData = async (
    getData,
    setDataCallback,
    searchKey,
    setLoadingCallback
  ) => {
    try {
      const response = await getData(searchKey || "");
      setDataCallback(response?.data?.data);
    } catch (error) {
      console.error("Error fetching deopdown data:", error);
    } finally {
    }
  };

  const getSuppliersForSearch = () => {
    fetchData(getAllSuppliersData, setSuppliers, searchSupplier, () => {});
  };
  useEffect(() => {
    getSuppliersForSearch();
  }, [searchSupplier]);

  return (
    <div>
      <StockInLayout
        searchSupplier={searchSupplier}
        setSearchSupplier={setSearchSupplier}
        stockInData={singleStockInData}
        suppliers={suppliers}
      />
    </div>
  );
};

export default StockInPage;
