import React from "react";
import SignupLayout from "../../../components/auth/signup/SignupLayout";

const SignupPage = () => {
  return (
    <div>
      <SignupLayout />
    </div>
  );
};

export default SignupPage;
