import React, { useEffect, useRef, useState } from "react";
import {
  getHospitalInfoPrint,
  getHospitalLogoPrint,
} from "../../api/settings/Settings";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import Button from "../common/buttons/Button";
import { groupClinicalData } from "../../utils/clinicalData";
import InputBox from "../common/input/InputBox";
import {
  getEditClinicalDataPrint,
  getInvoicesPrint,
  getLabOrdersPrint,
  getPrescriptionsPrint,
  getSingleTreatmentPrint,
  getTreatmentFilesPrint,
  getUpcomingTreatmentsPrint,
} from "../../api/Treatments";
import { displayDate } from "../../utils/date";
import { getSingleAppointmentsPrint } from "../../api/Appointments";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { showErrorToast } from "../../utils/toaster";
import { useLocation } from "react-router-dom";

const initialComponents = {
  ClinicalData: true,
  TreatmentDone: true,
  Upcomingtreatment: true,
  Prescription: true,
  LabOrder: true,
  Invoice: true,
  UploadImages: true,
};
const parseQueryParams = (queryString) => {
  const params = new URLSearchParams(queryString);
  const components = {
    ClinicalData: params.get("ClinicalData") === "true",
    TreatmentDone: params.get("TreatmentDone") === "true",
    Upcomingtreatment: params.get("Upcomingtreatment") === "true",
    Prescription: params.get("Prescription") === "true",
    LabOrder: params.get("LabOrder") === "true",
    Invoice: params.get("Invoice") === "true",
    UploadImages: params.get("UploadImages") === "true",
  };
  return components;
};

const TreatmentPrint = () => {
  const componentRef = useRef();
  const [details, setDetails] = useState("");
  const currentUser = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [logoImage, setLogoImage] = useState("");
  const hospitalInfo = useSelector((state) => state.hospitalInfo);
  const [dynamicFields, setDynamicFields] = useState([]);
  // Initial state for visibility of components
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [clinicalData, setClinicalData] = useState([]);
  const [upcomingTreatments, setUpcomingTreatments] = useState([]);
  const [prescriptions, setPrescription] = useState([]);
  const [labOrders, setLabOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [oldImages, setOldImages] = useState(appointments.images);
  useEffect(() => {
    setOldImages(appointments.images);
  }, [appointments]);

  // Get the current location
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const companyId = queryParams.get("companyId");
  const [visibility, setVisibility] = useState(
    parseQueryParams(location.search)
  );
  useEffect(() => {
    setVisibility(parseQueryParams(location.search));
  }, [location.search]);

  const [treatmentDetails, setTreatmentDetails] = useState({
    treatment_done: {
      notes: "",
      date: "",
      treatment_done_info: [],
    },
    next_treatment: {
      appointment_date: "",
      appointment_time: "",
      doctor: "",
      notes: "",
      next_treatment_info: [],
    },
    prescription: {
      date: "",
      medicine_info: [],
      notes: "",
      _id: "",
    },
    lab_info: [],
    invoice: {
      date: "",
      treatment_done_info: [],
      treatment_amount: "",
      total_amount: "",
      no_bill: "",
      _id: "",
    },
  });

  useEffect(() => {
    setTreatmentDetails({
      treatment_done: {
        notes: appointments?.notes,
        date: appointments?.date,
        treatment_done_info:
          appointments?.treatment_done_info?.length > 0
            ? appointments?.treatment_done_info.map((item) =>
                item.quantity ? item : { ...item, quantity: 1 }
              )
            : [],
      },
      next_treatment: {
        appointment_date: upcomingTreatments?.appointment_date,
        appointment_time: upcomingTreatments?.appointment_time,
        doctor: upcomingTreatments?.doctor,
        notes: upcomingTreatments?.notes,
        next_treatment_info: upcomingTreatments?.next_treatment_info || [],
      },
      prescription: {
        date: prescriptions?.date,
        medicine_info: prescriptions?.medicine_info || [],
        notes: prescriptions?.notes,
        _id: prescriptions?._id,
      },
      lab_info: labOrders.length > 0 ? labOrders : [],
      invoice: {
        date: invoices?.date || appointments?.appointment_date,
        treatment_done_info: invoices?.treatment_done_info || [],
        treatment_amount: invoices?.treatment_amount,
        total_amount: invoices?.total_amount,
        no_bill: invoices?.no_bill,
        _id: invoices?._id,
      },
    });
  }, [appointments, upcomingTreatments, prescriptions, labOrders, invoices]);

  useEffect(() => {
    const dynamicFields = groupClinicalData({ clinicalData: clinicalData });
    setDynamicFields(dynamicFields);
  }, [clinicalData]);

  const getLogoPic = () => {
    getHospitalLogoPrint(companyId)
      .then((response) => {
        setLogoImage(response);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {});
  };

  const getPrefix = () => {
    setLoading(true);
    getHospitalInfoPrint(companyId)
      .then((response) => {
        setDetails(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAppointmentDetails = async () => {
    if (!appointments?.appointment_id) return;
    try {
      const response = await getSingleAppointmentsPrint(
        appointments?.appointment_id
      );
      setAppointmentDetails(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAppointmentDetails();
  }, [appointments]);

  const getTreatmentDetails = () => {
    // setLoading(true);
    getSingleTreatmentPrint(id)
      .then((response) => {
        setAppointments(response.data?.data);
        getClinicalDataInfo(response?.data?.data?.patient_id?._id);
      })
      .catch((error) => {
        showErrorToast(error, "error");
        // redirect();
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const getClinicalDataInfo = (patientId) => {
    if (patientId) {
      getEditClinicalDataPrint(patientId, id)
        .then((response) => {
          if (response?.data.patientTreatmentConfiguration) {
            setClinicalData(response?.data.patientTreatmentConfiguration.data);
          }
        })
        .catch((error) => {
          console.log(error);
          // showErrorToast(error, "error");
        })
        .finally(() => {});
    }
  };

  const fetchMainData = async (getData, setDataCallback) => {
    try {
      const response = await getData(id);
      setDataCallback(response?.data?.data);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };
  const reactToPrintRef = useRef(null);

  const getAllData = async () => {
    setLoading(true);

    try {
      await Promise.all([
        fetchMainData(getUpcomingTreatmentsPrint, setUpcomingTreatments),
        fetchMainData(getPrescriptionsPrint, setPrescription),
        fetchMainData(getLabOrdersPrint, setLabOrders),
        fetchMainData(getInvoicesPrint, setInvoices),
        getTreatmentDetails(),
      ]);
      const timer = setTimeout(() => {
        reactToPrintRef.current?.handlePrint();
      }, 2500); // Adjust the delay as needed

      return () => clearTimeout(timer);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, [id]);

  useEffect(() => {
    getPrefix();
    getLogoPic();
  }, []);

  /** Function for rendering heading */
  const renderHeader = (group) => {
    switch (group[0]?.type) {
      case "h1_header":
        return <h1>{group[0].label}</h1>;
      case "h2_header":
        return <h2>{group[0].label}</h2>;
      case "h3_header":
        return (
          <div className="">
            <h1 className=" text-darkgrey">{group[0].label}</h1>
          </div>
        );
      case "h4_header":
        return <h1 className=" text-darkgrey">{group[0].label}</h1>;
      case "h5_header":
        return <h1 className=" text-darkgrey">{group[0].label}</h1>;
      case "divider":
        return (
          <div className="border-[1.5px] border-[#B9B9B9] h-2 w-full"></div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const footer = document.querySelector(".footer");
    if (footer) {
      // Set the current date in dd/mm/yyyy format
      const currentDate = new Date();
      const formattedDate = `${String(currentDate.getDate()).padStart(
        2,
        "0"
      )}/${String(currentDate.getMonth() + 1).padStart(
        2,
        "0"
      )}/${currentDate.getFullYear()}`;
      const dateElement = document.createElement("div");
      dateElement.textContent = formattedDate;
      footer.querySelector(".date").appendChild(dateElement);
    }
  }, []);

  // Handle checkbox change
  const handleCheckboxChange = (component) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [component]: !prevVisibility[component],
    }));
  };

  const [imageUrls, setImageUrls] = useState([]);
  const [oldImageTypes, setOldImageTypes] = useState([]); // Array to store file types

  useEffect(() => {
    const fetchTypes = async () => {
      if (!oldImages) {
        // Handle case where oldImages is undefined or null
        return;
      }
      const types = oldImages.map((image) => getFileExtension(image));
      setOldImageTypes(types);
      setOldImageTypes(types);
    };

    fetchTypes();
  }, [oldImages]);

  function getFileExtension(filename) {
    if (filename) {
      const parts = filename.split(".");
      if (parts.length > 1) {
        const extension = parts.pop().toLowerCase();
        if (extension === "pdf") {
          return "pdf"; // Return 'pdf' for pdf file types
        } else if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
          return "image"; // Return 'image' for image file types
        }
      }
    }
    return ""; // Return an empty string if filename is not provided or doesn't have an allowed extension
  }

  const getFiles = async () => {
    try {
      if (!oldImages || !imageUrls) {
        // Handle case where oldImages is undefined
        console.error("oldImages is undefined");
        return;
      }
      const promises = oldImages?.map(
        async (image) => await getTreatmentFilesPrint(image)
      );
      const urls = await Promise.all(promises);
      setImageUrls(urls);
    } catch (error) {
      console.error("Error fetching image URLs", error);
    }
  };

  // Extend dayjs with the custom parse format plugin
  dayjs.extend(customParseFormat);

  function formatTimeWithAmPm(timeString) {
    try {
      const date = dayjs(timeString, "HH:mm", true);
      return date.isValid() ? date.format("h:mm A") : "-";
    } catch (error) {}
  }

  useEffect(() => {
    getFiles();
  }, [oldImages]);

  const afterPrintDone = () => {
    // Function to detect if the device is mobile
    const isMobileDevice = () => {
      return /Mobi|Android|iPhone|iPad|iPod/.test(navigator.userAgent);
    };

    if (!isMobileDevice()) {
      window.close();
    }
  };

  return (
    <div className="grid justify-center pb-10 bg-gray-200 ">
      {/*  */}

      <div className="h-screen flex items-center justify-center">
        Just a moment, getting your print ready...
      </div>
      {/* no-print */}
      <div className="hidden">
        <div className="flex justify-end no-print">
          <div className="w-[150px]">
            <ReactToPrint
              trigger={() => (
                <div className="flex justify-center mt-5 ">
                  <Button
                    action={"button"}
                    className={"bg-pink-gradient text-heading2B text-white "}
                  >
                    Print
                  </Button>
                </div>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <div className="border w-fit mt-5 bg-white">
          <div ref={componentRef} class="w-[795px] text-sm print:w-auto">
            <div className="">
              <div className="flex justify-between">
                <div className="p-5 border-r-2">
                  {/* <!-- Image head --> */}
                  <div className="flex justify-center ">
                    <img
                      src={logoImage}
                      className="max-h-[100px] object-fill w-full"
                    />
                  </div>
                  <div className="mt-2 flex gap-1 flex-wrap">
                    <p>{details.address},</p>
                    <p>{details.address1},</p>
                    <p>{details.address2},</p>
                    <p>{details.addressLine1},</p>
                    <p>{details.addressLine2},</p>
                    <p>{details.addressLine3},</p>
                    <p>{details.addressLine4},</p>
                  </div>
                  <div className="mt-2 flex gap-1 flex-wrap">
                    <div className="flex">
                      <p className="w-fit text-sm">Phone - </p>
                      <p> {details?.phoneNumber1} </p>
                    </div>
                  </div>
                </div>
                <div className="grid justify-between p-5">
                  <div className="flex justify-center items-end p-2 mt-5">
                    <h2 className="text-xl font-bold min-w-max">CASE SHEET</h2>
                  </div>
                  <div className=" flex items-end justify-end">
                    {displayDate(Date.now())}
                  </div>
                </div>
              </div>
              <div className=" border-b border-black"></div>
              {/* <!-- Body invoice --> */}
              {/* Patient Details */}
              <div className="p-5 pl-5 grid grid-cols-2 w-full mt-2 pb-5">
                <div className="">
                  <h2 className=" font-bold text-base">Patient Details</h2>
                  <div className=" w-full mt-5">
                    <div className="space-y-2">
                      <div className="flex">
                        <span className="w-[200px] font-semibold">
                          Patient ID
                        </span>
                        <span>{appointments?.patient_id?.patient_id} </span>
                      </div>
                      <div className="flex">
                        <span className="w-[200px] font-semibold">Name</span>
                        <span className="min-w-max">
                          {appointments?.patient_id?.name}{" "}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="w-[200px] font-semibold">
                          Age / Gender
                        </span>
                        <span className="min-w-max">
                          {appointments?.patient_id?.age} /
                          {appointments?.patient_id?.gender}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 space-y-2">
                  <div className="flex">
                    <span className="w-[200px] font-semibold">Phone</span>
                    <span className="min-w-max">
                      {appointments?.patient_id?.phone}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="w-[200px] font-semibold">Address</span>
                    <span className="min-w-max">
                      {appointments?.patient_id?.address}
                    </span>
                  </div>
                </div>
              </div>
              <div className=" border-b border-black"></div>
              <div className="p-5 pl-5 flex flex-wrap gap-5 w-full mt-2 pb-5">
                <div className="">
                  <h2 className=" font-bold text-base">Appointment Details</h2>
                  <div className=" w-full mt-5">
                    <div className="space-y-2 pr-5 border-r border-black h-[50px]">
                      <div className="flex">
                        <span className="w-[150px] font-semibold">
                          Treatment
                        </span>
                        <span>
                          {appointmentDetails &&
                          appointmentDetails?.next_treatment_info?.length > 0
                            ? appointmentDetails?.next_treatment_info?.map(
                                (item, index) => (
                                  <div key={index}>{item?.name || "-"}</div>
                                )
                              )
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10  space-y-2 border-r pr-5 border-black h-[52px]">
                  <div className="flex">
                    <span className="w-[150px] font-semibold">Date</span>
                    <span className="min-w-max">
                      {displayDate(appointmentDetails?.appointment_date) || "-"}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="w-[150px] font-semibold">Time</span>
                    <span className="min-w-max">
                      {formatTimeWithAmPm(
                        appointmentDetails?.appointment_time
                      ) || "-"}
                    </span>
                  </div>
                </div>
                <div className="mt-10 space-y-2">
                  <div className="flex ">
                    <span className="w-[150px] font-semibold">Doctor</span>
                    <span className="min-w-max">
                      {appointmentDetails?.doctor || "-"}
                    </span>
                  </div>
                </div>
              </div>
              {/* Appointment Details */}
              <div className=" border-b border-black mt-2"></div>
              {visibility.ClinicalData && (
                <>
                  <div className="p-5 pl-5 mt-2 pb-5">
                    <h2 className=" font-bold text-base ">Clinical Data</h2>
                    {/* Single card */}
                    {dynamicFields.map((group, index) => {
                      // Check if the group has any non-empty values
                      const hasValues = group.some((item) => {
                        if (typeof item.value === "object") {
                          // Check if any property in the object has a non-empty value
                          return Object.values(item.value).some(
                            (val) => val !== ""
                          );
                        } else {
                          // Check if the value is not empty
                          return item.value && item.value !== "";
                        }
                      });
                      // Skip rendering if the group has no non-empty values
                      if (!hasValues) {
                        return null;
                      }
                      return (
                        /* Single card */
                        <div
                          key={index}
                          className="  pt-5"
                          style={{ pageBreakInside: "avoid" }}
                        >
                          {/* For rendering single Heading */}
                          {group.length === 1 && renderHeader(group)}

                          {/* Rendesing heading that have >1 group */}
                          {group.length > 1 && (
                            <div className="!font-semibold !text-base mb-2">
                              {renderHeader(group)}
                            </div>
                          )}
                          <div className="border-2 pl-3 pr-3 pt-2">
                            {group.length > 1 && (
                              <div className=" min-h-[100px]">
                                <div className="w-full  flex gap-3 flex-wrap ">
                                  {group.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {/* Render Input Text */}
                                      {item.type === "input_text" &&
                                        item.value && (
                                          <>
                                            <div>
                                              <div className="flex flex-col    ">
                                                <p className="text-darkgrey font-semibold text-sm ">
                                                  {item.label}
                                                </p>
                                                <div className=" ">
                                                  {item?.value}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {/* Render Input Boolean */}
                                      {item.type === "input_boolean" &&
                                        item.value && (
                                          <>
                                            <div>
                                              <div className="flex flex-col    ">
                                                <p className="text-darkgrey font-semibold text-sm ">
                                                  {item.label}
                                                </p>
                                                <div className=" ">
                                                  {item?.value}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {/* Render Input Switch */}
                                      {item.type === "input_switch" &&
                                        item.value && (
                                          <div className="flex flex-col    ">
                                            <p className="text-darkgrey font-semibold text-sm ">
                                              {item.label}
                                            </p>
                                            <div className=" mt-2">
                                              {item?.value ? "Yes" : "No"}
                                            </div>
                                          </div>
                                        )}

                                      {/* Render Input Checkbox */}
                                      {item.type === "input_checkbox" &&
                                        item.value && (
                                          <div>
                                            <div className="flex flex-col    ">
                                              <p className="text-darkgrey font-semibold text-sm ">
                                                {item.label}
                                              </p>
                                              <div className=" mt-2 ">
                                                {item?.value ? "Yes" : "No"}
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {/* Render Input Textarea */}
                                      {item.type === "input_textarea" &&
                                        item.value && (
                                          <div className="lg:flex-1 min-w-[270px] max-w-min ">
                                            <p className="text-sm font-semibold text-darkgrey">
                                              {item.label}
                                            </p>
                                            <div className="    min-h-[80px] p-2">
                                              {item?.value}
                                            </div>
                                          </div>
                                        )}

                                      {/* Render Input Textarea Full width*/}
                                      {item.type === "input_textareafull" &&
                                        item.value && (
                                          <div className="lg:flex-1 min-w-full max-w-min ">
                                            <p className="text-sm font-semibold text-darkgrey">
                                              {item.label}
                                            </p>
                                            <div className="    min-h-[80px] ">
                                              {item?.value}
                                            </div>
                                          </div>
                                        )}

                                      {/* Render Four Quadrant Box */}
                                      {item.type === "input_fourquadrant_box" &&
                                        Object.values(item?.value).some(
                                          (value) => value
                                        ) && (
                                          <div className="mb-3 ">
                                            <p className="text-sm font-semibold text-darkgrey">
                                              {item.label}
                                            </p>
                                            <div className="">
                                              <FourquadrantBox
                                                values={item.value || ""}
                                                disabled={true}
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className=" border-b border-black mt-2"></div>
                </>
              )}

              {visibility.TreatmentDone && (
                <>
                  {/* Treatment done */}
                  <div className="p-5 pl-5 mt-2 pb-5 ">
                    <h2 className="font-semibold text-base">Treatment Done</h2>
                    <div className="border mt-4 overflow-hidden">
                      <table className=" w-screen md:w-full h-auto min-w-max">
                        <thead className="">
                          <tr>
                            <th className={`text-bodyBB border text-left p-2 `}>
                              Teeth{" "}
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Treatment
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Doctor{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {treatmentDetails.treatment_done?.treatment_done_info?.map(
                            (item, index) => (
                              <tr
                                className=""
                                style={{ pageBreakInside: "avoid" }}
                              >
                                <td className=" border text-left p-2">
                                  <FourquadrantBox
                                    values={item}
                                    disabled={true}
                                  />
                                </td>
                                <td className="border text-left pl-2">
                                  {item?.name}
                                </td>
                                <td className="border text-left pl-2">
                                  {item?.doctor}
                                </td>
                              </tr>
                            )
                          )}

                          {/* Maping the rows */}
                        </tbody>
                      </table>
                      <div className="mt-2 pl-2 pb-2">
                        <div className="">
                          <p className="text-base text-darkgrey font-semibold">
                            Notes:
                          </p>
                          <div className="font-medium">
                            {treatmentDetails.treatment_done?.notes}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Treatment done */}
                </>
              )}
              <div className=" border-b border-black mt-2"></div>

              {visibility.Upcomingtreatment && (
                <>
                  {/* Upcoming Treatment  */}
                  <div
                    className="p-5 pl-5 mt-2 pb-5"
                    style={{ pageBreakInside: "avoid" }}
                  >
                    <h2 className="font-semibold text-base">
                      Upcoming Treatment
                    </h2>
                    <div className="border mt-4">
                      <div className="overflow-hidden">
                        <table className=" w-screen md:w-full h-auto min-w-max">
                          <thead className="">
                            <tr>
                              <th
                                className={`text-bodyBB border text-left p-2 `}
                              >
                                Doctor{" "}
                              </th>
                              <th
                                className={`text-bodyBB  border text-left pl-2`}
                              >
                                Date
                              </th>
                              <th
                                className={`text-bodyBB  border text-left pl-2`}
                              >
                                Time{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="">
                              <td className=" border text-left p-2">
                                {treatmentDetails.next_treatment?.doctor}
                              </td>
                              <td className="border text-left pl-2">
                                {displayDate(
                                  treatmentDetails.next_treatment
                                    ?.appointment_date
                                )}{" "}
                              </td>
                              <td className="border text-left pl-2">
                                {formatTimeWithAmPm(
                                  treatmentDetails.next_treatment
                                    ?.appointment_time
                                )}{" "}
                              </td>
                            </tr>

                            {/* Maping the rows */}
                          </tbody>
                        </table>
                      </div>

                      {/* <div className=" border-b  mt-2"></div> */}
                      <div className="overflow-hidden">
                        <table className=" w-screen md:w-full h-auto min-w-max">
                          <thead className="">
                            <tr>
                              <th
                                className={`text-bodyBB border text-left p-2 `}
                              >
                                Teeth{" "}
                              </th>
                              <th
                                className={`text-bodyBB  border text-left pl-2`}
                              >
                                Treatment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {treatmentDetails.next_treatment?.next_treatment_info?.map(
                              (item, index) => (
                                <tr className="">
                                  <td className=" border text-left p-2">
                                    <FourquadrantBox
                                      values={item}
                                      disabled={true}
                                    />{" "}
                                  </td>
                                  <td className="border text-left pl-2">
                                    {item?.name}
                                  </td>
                                </tr>
                              )
                            )}

                            {/* Maping the rows */}
                          </tbody>
                        </table>
                        <div className="mt-2 pl-2 mb-2">
                          <div className="">
                            <p className="text-base text-darkgrey font-semibold">
                              Notes:
                            </p>
                            <div className="">
                              {treatmentDetails?.next_treatment?.notes || "-"}
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Upcoming Treatment  */}
                  <div className=" border-b border-black mt-2"></div>
                </>
              )}

              {visibility.Prescription && (
                <>
                  {/* Prescription  */}
                  <div className="p-5 pl-5 mt-2 pb-5 ">
                    <h2 className="font-semibold text-base">Prescription</h2>

                    <div className="border mt-4 overflow-hidden">
                      <table className=" w-screen md:w-full h-auto min-w-max">
                        <thead className="">
                          <tr>
                            <th className={`text-bodyBB border text-left p-2 `}>
                              Sl No
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Medicine
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              BF / AF
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Dosage
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2 pr-5`}
                            >
                              Days
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {treatmentDetails.prescription?.medicine_info?.map(
                            (item, index) => (
                              <tr
                                key={index}
                                className=""
                                style={{ pageBreakInside: "avoid" }}
                              >
                                <td className="pl-5 border text-left p-2">
                                  {index + 1}
                                </td>
                                <td className="border text-left pl-2">
                                  {item?.medicine}
                                </td>
                                <td className="border text-left pl-2">
                                  {item?.before_after_food}
                                </td>
                                <td className="border text-left pl-2">
                                  {item.morning || 0} - {item?.afternoon || 0} -{" "}
                                  {item?.evening || 0} - {item?.night || 0}
                                </td>
                                <td className="pl-3 border text-left ">
                                  {item?.days}
                                </td>
                              </tr>
                            )
                          )}

                          {/* Maping the rows */}
                        </tbody>
                      </table>
                      <div className="mt-2 pl-2">
                        <div className="">
                          <p className="text-base font-semibold text-darkgrey">
                            Notes:
                          </p>
                          <div className="font-medium">
                            {treatmentDetails?.prescription?.notes || "-"}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Prescription  */}
                  <div className=" border-b border-black mt-2"></div>
                </>
              )}

              {visibility.LabOrder && (
                <>
                  {/* Lab Order*/}
                  <div className="p-5 pl-5 mt-2 pb-5">
                    <h2 className="font-semibold text-base">Lab Order</h2>

                    <div className="border mt-4 overflow-hidden">
                      <table className=" w-screen md:w-full h-auto   min-w-max">
                        <thead className="">
                          <tr>
                            <th
                              className={`text-bodyBB text-left pb-2 border p-2 `}
                            >
                              Sl No
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Teeth
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Lab work
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Notes
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {treatmentDetails.lab_info?.map((item, index) => (
                            <tr
                              key={index}
                              className=""
                              style={{ pageBreakInside: "avoid" }}
                            >
                              <td className="pl-5 border text-left p-2">
                                {index + 1}
                              </td>
                              <td className="border text-left pl-2">
                                {" "}
                                <FourquadrantBox
                                  values={item}
                                  disabled={true}
                                />
                              </td>
                              <td className="border text-left pl-2">
                                {item?.work_type}
                              </td>
                              <td className="border text-left pl-2">
                                {item?.notes || "-"}
                              </td>
                            </tr>
                          ))}

                          {/* Maping the rows */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Lab Order */}
                  <div className=" border-b border-black mt-2"></div>
                </>
              )}

              {visibility.Invoice && (
                <>
                  {/* Invoice*/}
                  <div className="p-5 pl-5 mt-2 pb-5">
                    <h2 className="font-semibold text-base">Invoice</h2>

                    <div className="border overflow-hidden mt-4">
                      <table className="pl-3 pr-3 pt-2 w-screen md:w-full h-auto min-w-max">
                        <thead className="">
                          <tr>
                            <th className={`text-bodyBB text-left  border p-2`}>
                              Sl No
                            </th>
                            <th
                              className={`text-bodyBB  border text-left pl-2`}
                            >
                              Service/Treatment
                            </th>
                            <th
                              className={`text-bodyBB   border text-left pl-2 p-2`}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {treatmentDetails.invoice?.treatment_done_info?.map(
                            (item, index) => (
                              <tr
                                key={index}
                                className=""
                                style={{ pageBreakInside: "avoid" }}
                              >
                                <td className="pl-5 border text-left p-2">
                                  {index + 1}
                                </td>
                                <td className="border text-left pl-2">
                                  {item?.name}
                                </td>
                                <td className="text-center border pl-2">
                                  {item?.amount}
                                </td>
                              </tr>
                            )
                          )}

                          {/* Maping the rows */}
                        </tbody>
                      </table>
                      <div className=" border-b mb-2"></div>
                      <div className="pl-3 pr-3 pt-2 flex justify-end">
                        <div>
                          <div className="flex gap-2 items-center mr-[45px]">
                            <p className="text-base font-semibold text-darkgrey">
                              Total Amount{" "}
                            </p>
                            <div className="text-base font-medium">
                              {treatmentDetails?.invoice?.total_amount}
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                  {/* Invoice */}
                  <div className=" border-b border-black mt-2"></div>
                </>
              )}
              {visibility.UploadImages && (
                <>
                  {/* Invoice*/}
                  <div
                    className="p-5 pl-5 mt-2 pb-5"
                    style={{ pageBreakInside: "avoid" }}
                  >
                    <h2 className="font-semibold text-base">Upload Images</h2>

                    <div className="border  mt-4 p-3 grid grid-cols-2 gap-5">
                      {/*  */}

                      {oldImages &&
                        imageUrls &&
                        oldImages?.map((image, index) => {
                          const type = oldImageTypes[index];

                          return (
                            <div key={index} className="relative">
                              <div className="">
                                {type === "image" && (
                                  <img
                                    src={imageUrls[index]}
                                    className="w-full h-[400px] rounded-15 cursor-pointer object-cover"
                                    alt={`Image ${index}`}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {/* Invoice */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end opacity-0">
        <div className=" w-[150px]">
          <ReactToPrint
            ref={reactToPrintRef}
            trigger={() => (
              <div className="flex justify-center mt-5 ">
                <Button
                  action={"button"}
                  className={"bg-pink-gradient text-heading2B text-white "}
                >
                  Print
                </Button>
              </div>
            )}
            content={() => componentRef.current}
            onAfterPrint={afterPrintDone} // Close the tab after printing
          />
        </div>
      </div>
    </div>
  );
};

const FourquadrantBox = ({
  type,
  onChange,
  value,
  name,
  placeholder,
  error,
  className,
  disabled,
  values,
  handleChange,
}) => {
  return (
    <div className="flex">
      <div className="">
        <InputBox
          className={"w-[72px] h-[44px] rounded-[10px] text-center"}
          // type={"number"}
          placeholder={""}
          name={"tooth1"}
          value={values?.tooth1 || values?.teeth1}
          onChange={handleChange}
          disabled={disabled}
        />
        <InputBox
          className={"w-[72px] h-[44px] rounded-[10px] text-center"}
          // type={"number"}
          placeholder={""}
          name={"tooth3"}
          value={values?.tooth3 || values?.teeth3}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
      <div>
        <InputBox
          className={"w-[72px] h-[44px] rounded-[10px] text-center"}
          // type={"number"}
          placeholder={""}
          name={"tooth2"}
          value={values?.tooth2 || values?.teeth2}
          onChange={handleChange}
          disabled={disabled}
        />
        <InputBox
          className={"w-[72px] h-[44px] rounded-[10px] text-center"}
          // type={"number"}
          placeholder={""}
          name={"tooth4"}
          value={values?.tooth4 || values?.teeth4}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TreatmentPrint;
