import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
} from "recharts";

const DonutChart = ({income}) => {

  const COLORS = ["#CFBB00", "Blue", "#E45353", "#6AB483"];
  
  const data = [
    { name: "UPI", value:income?.totalUpi },
    { name: "Card", value:income?.totalCard },
    { name: "Cash", value:income?.totalCash },
    { name: "Bank transfer", value:income?.totalBankTransfer }
  ];
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(viewportWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    fill,
  }) => {
    const maxValue = data.reduce((max, item) => (item.value > max ? item.value : max), 0);
    if (value <= maxValue * 0.01) {
      return null;
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 2.1;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180)) - 6;
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180)) + 3 ;

    // Define your original and mobile styles
    const originalStyles = {
      rect: { width: 78, height: 25, x: -40, y: -14 },
      text: { fontSize: 12 },
    };

    const mobileStyles = {
      rect: { width: 50, height: 20, x: -25, y: -11 },
      text: { fontSize: 10 },
    };

    // Choose styles based on viewport width
    const styles = viewportWidth <= 768 ? mobileStyles : originalStyles;
    
    const { rect, text } = styles;
    
    return (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={rect.x}
          y={rect.y}
          rx="5"
          ry="5"
          fill={fill}
          width={rect.width}
          height={rect.height}
        />
        <text
          textAnchor="middle"
          alignmentBaseline="middle"
          style={{ fill: "white", fontWeight: "bold", fontSize: text.fontSize }}
        >
          {value}
        </text>
      </g>
    );
  };
  
  return (
    <div className=" p-5 w-[100%] h-[450px] flex items-center">
      <ResponsiveContainer style={{ width: "100%", height: "100%" }}>
        
        <PieChart>
          <Legend
            align={"center" }
            // verticalAlign={"bottom"}
            layout={"vertical" }
            iconSize={20}
            iconType="rect"
            borderRadius={5} // Set the border radius for the icons
            // wrapperStyle={{
            //   paddingRight: isMobile ? "0" : "0px", // Adjust the paddingRight as needed
            // }}
            content={(props) => {
              const { payload } = props;
              return (
                <ul className="flex flex-row  gap-4 ">
                  {payload.map((entry, index) => (
                    <li key={`legend-item-${index}`}>
                      <div className="flex flex-row items-center">
                        <span
                          className="legend-icon mt-5 w-[20px] h-[20px]"
                          style={{
                            backgroundColor: entry.color,
                            borderRadius: "5px", // Set border radius for icons
                            marginRight: "5px", // Adjust spacing between icon and text
                          }}
                        ></span>
                        <span
                          className="legend-text mt-5"
                          style={{
                            fontSize: "13px", // Set text size
                            fontFamily: "Arial", // Change font family
                            color: "#5C5C5C",
                            fontWeight: 400,
                          }}
                        >
                          {entry.value}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              );
            }}
          />
          <Pie
            data={data}
            cx={isMobile ? "50%" : "50%"}
            cy="50%"
            innerRadius={isMobile ? "30%" : "40%"} 
            outerRadius={isMobile ? "50%" : "65%"}
            fill="#8884d8"
            paddingAngle={0}
            labelLine={false}
            dataKey="value"
            label={CustomLabel}
          >
            {data.map((entry, index) => (
              <Cell
                style={{ outline: "none" }}
                key={`cell-${index}`}
                fill={COLORS[index]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DonutChart;
