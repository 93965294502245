import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import FullScreeeSpinner from "../loading/FullScreee";

const LineChartComponent = ({ stroke, graphData, showRevenue, loading }) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(viewportWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const data = graphData?.Graph_data?.datasets
    .find((dataset) => dataset.label === "Income")
    .data.map((value, index) => ({
      label: graphData?.Graph_data?.labels[index],
      value: value,
    }));
  const data1 = graphData?.Graph_data?.datasets
    .find((dataset) => dataset.label === "Expense")
    .data.map((value, index) => ({
      label: graphData?.Graph_data?.labels[index],
      value: value,
    }));
  const incomeData = graphData?.Graph_data?.datasets
    .find((dataset) => dataset.label === "Income")
    .data.map((value, index) => ({
      label: graphData?.Graph_data?.labels[index],
      income: value,
    }));

  const expenseData = graphData?.Graph_data?.datasets
    .find((dataset) => dataset.label === "Expense")
    .data.map((value, index) => ({
      label: graphData?.Graph_data?.labels[index],
      expense: value,
    }));

  let combinedData;
  let dataKey;
  // Check if incomeData and expenseData are defined and not empty
  if (incomeData && expenseData && incomeData?.length === expenseData?.length) {
    combinedData = incomeData.map((incomeItem, index) => ({
      label: incomeItem?.label,
      income: incomeItem?.income,
      expense: expenseData[index]?.expense,
    }));

    // console.log(combinedData, "combined data");
  } else {
    console.error(
      "Income data or expense data is undefined or empty, or their lengths do not match."
    );
  }
  if (incomeData && expenseData && incomeData?.length === expenseData?.length) {
    // Calculate the maximum value for income and expense
    const maxIncome = Math.max(...combinedData?.map((item) => item?.income));
    const maxExpense = Math.max(...combinedData?.map((item) => item?.expense));

    // Determine which one has the higher value
    dataKey = maxIncome >= maxExpense ? "income" : "expense";
  }
  const data2 = graphData?.Graph_data?.datasets
    .find((dataset) => dataset.label === "Revenue")
    .data.map((value, index) => ({
      label: graphData?.Graph_data?.labels[index],
      value: value,
    }));

  // const data = [
  //   { label: "01/05/2023", value: 19 },
  //   { label: "01/06/2023", value: 14 },
  //   { label: "01/09/2023", value: 3 },
  //   { label: "01/07/2023", value: 24 },
  //   { label: "01/05/2023", value: 10 },
  // ];

  // const data1 = [
  //   { label: "01/05/2023", value: 25 },
  //   { label: "01/06/2023", value: 17 },
  //   { label: "01/09/2023", value: 22 },
  //   { label: "01/07/2023", value: 55 },
  //   { label: "01/05/2023", value: 6 },
  // ];
  const CustomizedYAxisTick = (props) => (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} dy={2} textAnchor="end" fill="#666" fontSize={12}>
        {props.payload.value}
      </text>
    </g>
  );

  const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          // x={25}
          y={5}
          dy={15}
          // textAnchor="end"
          fontSize={9.5} // Adjust the font size as needed
          fill="#666" // Adjust the font color as needed
          //   transform="rotate(-45)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    // Find the data item from data2 that matches the label
    const dataItem = data2?.find((data) => data.label === label);

    // Extract the value from the found data item or set it to null if not found
    const dataForRevenue = dataItem ? dataItem.value : null;

    // Insert or update payload[2] with the derived value
    if (payload && payload.length >= 3) {
      payload[2].value = dataForRevenue;
    } else {
      // If payload[2] is not defined or payload doesn't have enough items, create a new item
      payload[2] = { value: dataForRevenue };
    }

    // Now payload[2] has been modified

    if (active && payload) {
      return (
        <div className=" bg-primary gap-1 p-1 pl-2 pr-2 rounded-[5px]  text-white">
          <p className="text-smallLB">{`${label} `}</p>

          <p className="text-smallBB">{`income : ${payload[0]?.value}`}</p>

          {payload[1] && (
            <p className="text-smallBB">{`expense : ${payload[1]?.value}`}</p>
          )}
          {showRevenue && payload[2] && (
            <p className="text-smallBB">{`revenue : ${payload[2]?.value}`}</p>
          )}
        </div>
      );
    }

    return null;
  };
  // if(loading) return <FullScreeeSpinner />
  return (
    <div className="flex w-full ">
      {/* <ResponsiveContainer width="100%" height={500}> */}
      <div style={{ minWidth: "100%", overflowX: "auto" }}>
        <LineChart
          data={combinedData}
          width={800}
          height={500}
          margin={
            isMobile
              ? { top: 10, right: 20, left: 20, bottom: 0 }
              : { top: 20, right: 30, left: 20, bottom: 20 }
          }
        >
          {/* <CartesianGrid strokeDasharray="3 5" /> */}
          <XAxis
            dataKey="label"
            tickLine={true}
            // tick={<CustomizedXAxisTick />}
            // allowDataOverflow={false}
            // tickMargin={5}
            // padding={{ left: 5, right: 20 }}
            padding={{ left: 30, right: 30 }}
            fontSize={12}
            dy={5}
          />
          <Legend verticalAlign="bottom" height={36} />
          <YAxis
            dataKey={dataKey}
            label={{
              value: "amount",
              angle: -90,
              position: "insideTopRight",
              offset: "-2",
            }}
          />
          <Tooltip content={<CustomTooltip />} />

          <Line
            type="linear"
            dataKey="income"
            stroke="#4575C3"
            // activeDot={{ r: 8 }}
            strokeWidth={3}
            dot={{ strokeWidth: 1 }}
            // data={data}
          />
          <Line
            type="linear"
            dataKey="expense"
            dot={{ strokeWidth: 1 }}
            // data={data1} // Use data1 array
            stroke="#FF5733" // Specify color for the new line
            // activeDot={{ r: 8 }}
            strokeWidth={4}
          />
          {/* <Line
              type="linear"
              dataKey="value"
              data={data2} // Use data1 array
              stroke="#FF5733" // Specify color for the new line
              activeDot={{ r: 8 }}
              strokeWidth={3}
            /> */}
        </LineChart>
      </div>
      {/* </ResponsiveContainer> */}
    </div>
  );
};

export default LineChartComponent;
