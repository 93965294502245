import React from "react";

const InventoryIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.2"
        cx="21.5"
        cy="21.5"
        r="21.5"
        fill="url(#paint0_linear_728_1374)"
      />
      <path
        d="M29.1 12H13.9C12.95 12 12 12.855 12 13.9V16.7595C12 17.4435 12.4085 18.0325 12.95 18.365V29.1C12.95 30.145 13.995 31 14.85 31H28.15C29.005 31 30.05 30.145 30.05 29.1V18.365C30.5915 18.0325 31 17.4435 31 16.7595V13.9C31 12.855 30.05 12 29.1 12ZM24.35 23.4H18.65V21.5H24.35V23.4ZM29.1 16.75H13.9V13.9L29.1 13.881V16.75Z"
        fill="url(#paint1_linear_728_1374)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_728_1374"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#219FD9" />
          <stop offset="1" stop-color="#219FD9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_728_1374"
          x1="21.5"
          y1="12"
          x2="21.5"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#219FD9" />
          <stop offset="1" stop-color="#219FD9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InventoryIcon;
