import { api } from "../../../config/axios/interceptor";

export async function getAllSuppliers(searchKey) {
  try {
    const response = await api.get(`inv_supplier_master?search=${searchKey}`);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function deleteSingleStockOut(_id) {
  const response = await api.delete(`inv_stock_out/${_id}`);
  return response;
}
export async function deleteSingleStockIn(_id) {
  const response = await api.delete(`inv_stock_in/${_id}`);
  return response;
}

export async function getSerialNumberAddSupplier() {
  try {
    const response = await api.get(`inv_supplier_master/getserialno`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function addSupplier(values) {
  try {
    const response = await api.post(`inv_supplier_master`, values);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateSupplier(values, id) {
  try {
    const response = await api.put(`inv_supplier_master/${id}`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteSupplier(id) {
  try {
    const response = await api.delete(`inv_supplier_master/` + id);
    return response;
  } catch (error) {
    throw error;
  }
}
