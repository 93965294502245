import React, { useEffect, useState } from "react";
import OTPInput from "./otp-field";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../../utils/toaster";
import { resendSignupOtp } from "../../../../api/auth/clenic-signup";

// Create the validation schema using Yup
const OTPValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(/^\d{4}$/, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

const OtpLayout = ({ phoneNumber, handleSubmitOtp }) => {
  const [isOTPSent, setIsOTPSent] = useState(true);
  const [timer, setTimer] = useState(30);

  // Initialize Formik
  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: OTPValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission
      handleSubmitOtp(values.otp, setSubmitting);
    },
  });

  const handleResendOTP = async () => {
    if (!isOTPSent) {
      try {
        await resendSignupOtp(formik.values.otp, phoneNumber);
        showSuccessToast(
          "New OTP has been sent. Please enter it to verify your Prone Number."
        );
        setIsOTPSent(true);
      } catch (error) {
        showErrorToast(error, "error");
      } finally {
      }
    }
  };

  const handleOnOtpChange = (value) => {
    const otpValue = value.join("");
    formik.setFieldValue("otp", otpValue);

    if (otpValue.length === 4) {
      // Validate the OTP value and submit the form
      setTimeout(() => {
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length === 0) {
            formik.submitForm();
          }
        });
      }, 0);
    }
  };

  useEffect(() => {
    let countdown;
    if (isOTPSent) {
      setTimer(30); // Set timer to 30 seconds
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [isOTPSent]);

  useEffect(() => {
    if (timer === 0 && isOTPSent) {
      setIsOTPSent(false);
    }
  }, [timer]);
  return (
    <div>
      <section className="w-full h-full grid grid-flow-col justify-center items-center relative">
        <form className="" onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="mb-10 text-center">
              <p className="text-[25px] text-heading2EB">
                <span className="  text-primary">Verify </span>
                your Phone Number
              </p>
            </div>
            <div className="mb-5 pt-1 text-center">
              <p className=" text-[14px] max-w-[500px] -mt-8 text-[#464646]">
                We have sent a verification code to{" "}
                <span className="font-semibold"> {phoneNumber}</span>
              </p>
            </div>
            <div className="grid gap-5 justify-center">
              <OTPInput onOtpChange={handleOnOtpChange} />
              <p className="text-xs font-gilroyregular text-danger h-1">
                {formik.touched.otp && formik.errors.otp}
              </p>
            </div>
            {/* <div>
              <Button loading={formik.isSubmitting} type="primary">
                Submit
              </Button>
            </div> */}
            <div className="mt-3 flex justify-center">
              <p className="text-[14px]  font-gilroyregular">
                Didn't receive the code?
                <span
                  onClick={handleResendOTP}
                  className={`  font-gilroymedium
                      ${!isOTPSent ? "text-primary cursor-pointer" : ""}
                      `}
                >
                  {" "}
                  {isOTPSent ? `Resend Code in ${timer}s` : "Resend Code"}
                  {/* Send a new OTP{" "} */}
                </span>
              </p>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default OtpLayout;
