// analyticsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { formatDate } from "../../utils/date";

const initialState = {
  // dateStart: formatDate(new Date()),
  // dateEnd: formatDate(new Date()),
  showRevenue: false,
  dateRangeDropDown: "This Month",
  startDate: null,
  endDate: null

};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    toggleShowRevenue(state) {
      state.showRevenue = !state.showRevenue;
    },
    setDateRange(state, action) {
      state.dateRangeDropDown = action.payload;
    },
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    }
  }
});

export const { toggleShowRevenue, setDateRange, setStartDate,setEndDate } =
  analyticsSlice.actions;

export default analyticsSlice.reducer;
