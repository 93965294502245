import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import {
  getHospitalInfoPrint,
  getHospitalLogoPrint,
} from "../../api/settings/Settings";
import { displayDate } from "../../utils/date";
import Button from "../common/buttons/Button";
import { useLocation } from "react-router-dom";
import { showErrorToast } from "../../utils/toaster";
import { getLabOrdersPrintSingle } from "../../api/Treatments";

const LabsInvoice = () => {
  const [labDetails, setLabDetails] = useState({});
  const componentRef = useRef();
  const [details, setDetails] = useState("");
  const currentUser = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [logoImage, setLogoImage] = useState("");
  const hospitalInfo = useSelector((state) => state.hospitalInfo);
  // Get the current location
  const location = useLocation();
  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const companyId = queryParams.get("companyId");
  const hospitalId = queryParams.get("hospitalId");
  const reactToPrintRef = useRef(null);

  const getLogoPic = () => {
    getHospitalLogoPrint(hospitalId)
      .then((response) => {
        setLogoImage(response);
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {});
  };

  const getPrefix = () => {
    setLoading(true);
    getHospitalInfoPrint(companyId)
      .then((response) => {
        setDetails(response.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPrefix();
    getLogoPic();
  }, []);

  useEffect(() => {
    setLoading(true);
    getLabOrdersPrintSingle(id)
      .then((response) => {
        setLabDetails(response.data.data);
        const timer = setTimeout(() => {
          reactToPrintRef.current?.handlePrint();
        }, 2500); // Adjust the delay as needed
      })
      .catch((error) => {
        showErrorToast(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const afterPrintDone = () => {
    // Function to detect if the device is mobile
    const isMobileDevice = () => {
      return /Mobi|Android|iPhone|iPad|iPod/.test(navigator.userAgent);
    };

    if (!isMobileDevice()) {
      window.close();
    }
  };

  return (
    <div className="grid justify-center pt-10 pb-10 bg-gray-200">
      {/*  */}
      <div className="h-screen flex items-center justify-center">
        Just a moment, getting your print ready...
      </div>
      <div className="hidden">
        <div className="flex justify-end mt-5">
          <div className="">
            <ReactToPrint
              trigger={() => (
                <div className=" w-[150px]">
                  <Button
                    action={"button"}
                    className={
                      "bg-pink-gradient text-heading2B text-white !h-[52px]"
                    }
                  >
                    Print
                  </Button>
                </div>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <div className="border w-fit mt-5 bg-white">
          <div ref={componentRef} class="w-[794px] text-sm print:w-auto">
            <div className="flex justify-between">
              <div className="p-5 border-r-2">
                {/* <!-- Image head --> */}
                <div className="flex justify-center ">
                  <img
                    src={logoImage}
                    className="max-h-[100px] object-fill w-full"
                  />
                </div>
                <div className="mt-2 flex gap-1 flex-wrap">
                  <p>{details.address},</p>
                  <p>{details.address1},</p>
                  <p>{details.address2},</p>
                  <p>{details.addressLine1},</p>
                  <p>{details.addressLine2},</p>
                  <p>{details.addressLine3},</p>
                  <p>{details.addressLine4},</p>
                </div>
                <div className="mt-2 flex gap-1 flex-wrap">
                  <div className="flex">
                    <p className="w-fit text-sm">Phone - </p>
                    <p> {details?.phoneNumber1} </p>
                  </div>
                </div>
              </div>
              <div className="grid justify-between p-5">
                <div className="flex justify-center items-end p-2 mt-5">
                  <h2 className="text-xl font-bold min-w-max">
                    LAB ORDER SHEET
                  </h2>
                </div>
                <div className=" flex items-end justify-end">
                  {displayDate(Date.now())}
                </div>
              </div>
            </div>
            <div className=" border-b border-black mt-2"></div>
            {/* <!-- Body invoice --> */}
            {/* Patient Details */}
            <div className="p-5 pl-5 grid grid-cols-2 w-full mt-2 pb-5">
              <div className="border-r border-black">
                <h2 className="underline font-semibold text-base">
                  Patient Details
                </h2>
                <div className=" items-center mt-5">
                  <div className="space-y-2">
                    <div className="flex">
                      <span className="w-[200px]">Patient ID</span>
                      <span>{labDetails?.patient_id?.patient_id} </span>
                    </div>
                    <div className="flex">
                      <span className="w-[200px]">Name</span>
                      <span className="min-w-max">
                        {labDetails?.patient_id?.name}{" "}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="w-[200px]">Gender</span>
                      <span className="min-w-max">
                        {labDetails?.patient_id?.gender}
                      </span>
                    </div>

                    <div className="flex">
                      <span className="w-[200px]">Age </span>
                      <span className="min-w-max">
                        {labDetails?.patient_id?.age}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid justify-end pr-5">
                <h2 className="underline font-semibold -mb-2 text-base">
                  Lab Details{" "}
                </h2>
                <div className="">
                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <span className="w-[200px]">Lab Name </span>
                      <span>{labDetails?.lab} </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="w-[200px]">Contact Person</span>
                      <span>{labDetails?.patient_id?.name} </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="w-[200px]">Contact Number</span>
                      <span>{labDetails?.patient_id?.phone} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" border-b border-black"></div>

            {/* Appointment Details */}
            <div className="p-5 mt-3 mb-5">
              <div className="">
                <h2 className="underline font-semibold text-base">
                  Lab Order Details{" "}
                </h2>
                <div className=" mt-5">
                  <div className=" flex justify-between">
                    <div className="space-y-2">
                      <div className="flex">
                        <span className="w-[200px]">Lab Order ID </span>
                        <span>{labDetails?.lab} </span>
                      </div>
                      <div className="flex">
                        <span className="w-[200px]">Order Date </span>
                        <span>{displayDate(labDetails?.order_date)} </span>
                      </div>
                      <div className="flex">
                        <span className="w-[200px]">
                          Expected Arrival Date{" "}
                        </span>
                        <span>
                          {displayDate(labDetails?.expected_arrival_date)}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      {labDetails?.arrival_date && (
                        <div className="flex">
                          <span className="w-[200px]">Arrival Date </span>
                          <span>{displayDate(labDetails?.arrival_date)} </span>
                        </div>
                      )}
                      {labDetails?.fixing_date && (
                        <div className="flex">
                          <span className="w-[200px]">Delivery Date </span>
                          <span>{displayDate(labDetails?.fixing_date)} </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className=" border-b border-black"></div>

            <div className=" p-5 grid grid-cols-1 ">
              <div className="col-span-2 mb-11">
                <h2 className="underline font-semibold text-base">
                  Lab Work Details{" "}
                </h2>
                <div className=" mt-5">
                  <div className="space-y-2">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex">
                        <span className="w-[150px]">Tooth </span>
                        <div className="w-[100px] h-fit">
                          <div className="flex flex-wrap w-[200px]">
                            <input
                              readOnly
                              disabled={true}
                              value={labDetails.teeth1}
                              className={` border-[1.5px] text-center border-[#B9B9B9] outline-none text-darkgrey text-bodyRB p-2 w-[100px] h-[44px]`}
                            />
                            <input
                              readOnly
                              disabled={true}
                              value={labDetails.teeth2}
                              className={` border-[1.5px] border-[#B9B9B9] text-center outline-none text-darkgrey text-bodyRB p-2 w-[100px] h-[44px]`}
                            />
                            <input
                              readOnly
                              disabled={true}
                              value={labDetails.teeth3}
                              className={` border-[1.5px] border-[#B9B9B9] text-center outline-none text-darkgrey text-bodyRB p-2 w-[100px] h-[44px]`}
                            />
                            <input
                              readOnly
                              disabled={true}
                              value={labDetails.teeth4}
                              className={` border-[1.5px] border-[#B9B9B9] text-center outline-none text-darkgrey text-bodyRB p-2 w-[100px] h-[44px]`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <span className="w-[200px]">Lab Work </span>
                        <span>{labDetails?.work_type} </span>
                      </div>
                      <div className="flex col-span-2">
                        <span className="min-w-[150px]">Notes</span>
                        <span className="min-h-[10px] w-full">
                          {labDetails?.notes}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              {/*  */}

              {/* Reworks */}
              {labDetails?.rework?.map((item, index) => (
                <div
                  key={index}
                  className="border border-black p-2 col-span-2 mt-5"
                  style={{ pageBreakInside: "avoid" }}
                >
                  <div>
                    <div className="">
                      <h2 className="underline font-semibold">
                        Rework ({index + 1})
                      </h2>
                      <div className=" mt-5">
                        <div className="space-y-2">
                          <div className="flex">
                            <span className="w-[210px]">
                              Rework Order Date{" "}
                            </span>
                            <span>{displayDate(item?.order_date)} </span>
                          </div>
                          <div className="flex">
                            <span className="w-[210px]">
                              Rework Exp Arrival Date{" "}
                            </span>
                            <span>
                              {displayDate(item.expected_arrival_date)}{" "}
                            </span>
                          </div>
                          <div className="flex col-span-2">
                            <span className="min-w-[210px]">Notes</span>
                            <span className="min-h-[50px]">
                              {item?.notes || "-"}
                            </span>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-5">
        <div className="opacity-0">
          <ReactToPrint
            ref={reactToPrintRef}
            trigger={() => (
              <div className=" w-[150px]">
                <Button
                  action={"button"}
                  className={
                    "bg-pink-gradient text-heading2B text-white !h-[52px]"
                  }
                >
                  Print
                </Button>
              </div>
            )}
            content={() => componentRef.current}
            onAfterPrint={afterPrintDone} // Close the tab after printing
          />
        </div>
      </div>
    </div>
  );
};

export default LabsInvoice;
