import React from "react";
import Button from "../../../common/buttons/Button";
import InputBox from "../../../common/input/InputBox";
import DatePicker from "../../../common/datepicker/DatePicker";
import RedClose from "../../../icons/Red-Close";
import InputBoxSelect from "../../../common/input/InputBoxSelect";
import dayjs from "dayjs";
import TextAreaBox from "../../../common/input/TextAreaBox";
import { blockInvalidChar } from "../../../../utils/other";

const BatchDetailsComponent = ({
  searchSupplier,
  setSearchSupplier,
  formik,
  addRow,
  removeRow,
  suppliers,
}) => {
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     const payload = {
  //       ...values,
  //       date: formatToSearchDate(values.date),
  //       patient_id: details?.patient_id?._id,
  //     };
  //     const id = details._id;
  //     setLoading(true);
  //     updatePrescription(payload, id)
  //       .then((response) => {
  //         showSuccessToast("Prescription Updated");
  //         prescriptionDetails();
  //       })
  //       .catch((error) => {
  //         showErrorToast(error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   },
  // });
  return (
    <div>
      <div className=" rounded-15 pb-4">
        <div
          className={`shadow-card rounded-tl-15 rounded-tr-15 bg-secondary p-2 flex flex-wrap gap-2 justify-between items-center mb-4`}
        >
          <p className="text-bodyBB text-darkgrey">Batch Details</p>
          <div className="lg:w-[200px] w-full">
            <Button
              type={"primary"}
              action={"button"}
              onClick={() => addRow(formik.values?.purchase_details.length + 1)}
              className={"py-[8px] lg:py-[10px] text-bodyBB"}
            >
              Add New Batch
            </Button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {formik.values?.purchase_details?.map((item, index) => (
            <div className="grid  lg:grid-cols-3 gap-5 w-full mt-2  p-5 shadow-card relative">
              {/* delete button */}
              {index > 0 && (
                <div
                  className="w-[50px] cursor-pointer absolute right-1 top-1"
                  onClick={() => removeRow(index)}
                >
                  <RedClose />
                </div>
              )}
              <div className="grid grid-flow-row ">
                <DatePicker
                  name={`purchase_details[${index}].purchase_date`}
                  onDateChange={(e) => {
                    formik.setFieldValue(
                      `purchase_details[${index}].purchase_date`,
                      dayjs(e).format("YYYY-MM-DD")
                    );
                  }}
                  title="Purchase Date"
                  // onDateChange={(e) => {
                  //   formik.setFieldValue(
                  //     "appointment_date",
                  //     dayjs(e).format("YYYY-MM-DD")
                  //   );
                  //   setSelectedDate(dayjs(e).format("YYYY-MM-DD"));
                  // }}
                  value={
                    formik.values?.purchase_details?.[index]?.purchase_date
                  }
                  height="50px"
                  startDate={
                    new Date(
                      formik.values?.purchase_details?.[index]?.purchase_date
                    )
                  }
                  error={
                    formik.touched?.purchase_details?.[index]?.purchase_date &&
                    formik.errors?.purchase_details?.[index]?.purchase_date
                  }
                  minDate={false}
                />
              </div>
              <div className="grid grid-flow-row ">
                <InputBox
                  type="text"
                  name={`purchase_details[${index}].invoice_number`}
                  title={"Invoice Number"}
                  placeholder="Enter Invoice Number"
                  onChange={formik.handleChange}
                  className="!h-[50px]"
                  value={
                    formik.values?.purchase_details?.[index]?.invoice_number
                  }
                  error={
                    formik.touched?.purchase_details?.[index]?.invoice_number &&
                    formik.errors?.purchase_details?.[index]?.invoice_number
                  }
                />
              </div>
              <div className="grid grid-flow-row ">
                <InputBox
                  type="text"
                  name={`purchase_details[${index}].batch`}
                  title={"Batch"}
                  placeholder="Enter Batch  Number"
                  onChange={formik.handleChange}
                  className="h-[50px]"
                  value={formik.values?.purchase_details?.[index]?.batch}
                  error={
                    formik.errors?.purchase_details?.[index]?.batch &&
                    formik.touched?.purchase_details?.[index]?.batch
                  }
                />
              </div>
              <div className="grid grid-flow-row ">
                <InputBoxSelect
                  title="Supplier"
                  placeholder={"Choose supplier"}
                  value={formik?.values?.purchase_details?.[index]?.supplier}
                  error={
                    formik?.touched?.purchase_details?.[index]?.supplier &&
                    formik?.errors?.purchase_details?.[index]?.supplier
                  }
                  // onChange={(e) => setSelectedCategory(e)}

                  onChange={(e) => {
                    setSearchSupplier("");
                    formik.setFieldValue(
                      `purchase_details.${index}.supplier`,
                      e
                    );
                  }}
                  className="h-[50px]"
                  arrowIcon={true}
                  handleInputChange={(e) => {
                    setSearchSupplier(e.target?.value);
                    formik.setFieldValue(
                      `purchase_details.${index}.supplier`,
                      e.target?.value
                    );
                  }}
                >
                  {suppliers?.map((each, index) => {
                    return (
                      <div key={index} value={each.name} className="">
                        {each.name}
                      </div>
                    );
                  })}
                </InputBoxSelect>
              </div>
              <div className="grid grid-flow-row ">
                <InputBox
                  type="number"
                  name={`purchase_details[${index}].quantity`}
                  title={"Purchase Quantity"}
                  placeholder="Enter Purchase Quantity"
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    formik.handleChange(e);
                  }}
                  value={formik.values?.purchase_details?.[index]?.quantity}
                  className="h-[50px]"
                  error={
                    formik.errors?.purchase_details?.[index]?.quantity &&
                    formik.touched?.purchase_details?.[index]?.quantity
                  }
                />
              </div>
              <div className="grid grid-flow-row ">
                <DatePicker
                  name={`purchase_details[${index}].expiry_date`}
                  title="Expiry Date"
                  height="50px"
                  error={
                    formik.touched?.purchase_details?.[index]?.expiry_date &&
                    formik.errors?.purchase_details?.[index]?.expiry_date
                  }
                  onDateChange={(e) => {
                    formik.setFieldValue(
                      `purchase_details[${index}].expiry_date`,
                      dayjs(e).format("YYYY-MM-DD")
                    );
                  }}
                  startDate={
                    formik.values?.purchase_details?.[index]?.expiry_date &&
                    new Date(
                      formik.values?.purchase_details?.[index]?.expiry_date
                    )
                  }
                  handleClear={() => {
                    formik.setFieldValue(
                      `purchase_details[${index}].expiry_date`,
                      ""
                    );
                  }}
                />
              </div>

              <div className="grid grid-flow-row ">
                <InputBox
                  type="number"
                  name={`purchase_details[${index}].purchase_price`}
                  title={"Purchase Price"}
                  placeholder="Enter Purchase Price"
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    formik.handleChange(e);
                  }}
                  value={
                    formik.values?.purchase_details?.[index]?.purchase_price
                  }
                  className="!h-[50px]"
                  error={
                    formik.errors?.purchase_details?.[index]?.purchase_price &&
                    formik.touched?.purchase_details?.[index]?.purchase_price
                  }
                />
              </div>
              <div className="grid grid-flow-row ">
                <TextAreaBox
                  title={"Notes"}
                  placeholder={"Enter Notes"}
                  name={`purchase_details[${index}].notes`}
                  onChange={formik.handleChange}
                  value={formik.values?.purchase_details?.[index]?.notes}
                  error={
                    formik.errors?.purchase_details?.[index]?.notes &&
                    formik.touched?.purchase_details?.[index]?.notes
                  }
                />
              </div>
            </div>
          ))}
          <div className="grid grid-cols-6">
            <div className="col-start-6 col-end-7 my-6 ">
              <Button
                // loading={loading}
                type={"primary"}
                className={" text-heading2B"}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BatchDetailsComponent;
