import { api } from "../../../config/axios/interceptor";

export async function addStockIn(values) {
  try {
    const response = await api.post(`inv_stock_in`, values);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getBatchId(index) {
  try {
    const response = await api.get(`inv_stock_in/getbatchno?index=${index}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}
export async function editStockIn(values, _id) {
  try {
    const response = await api.put(`inv_stock_in/${_id}`, values);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function addStockOut(values) {
  try {
    const response = await api.post(`inv_stock_out`, values);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function editStockOut(values, _id) {
  try {
    const response = await api.put(`inv_stock_out/${_id}`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSerialNumberStockIn() {
  try {
    const response = await api.get(`inv_stock_in/getserialno`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSerialNumberStockOut() {
  try {
    const response = await api.get(`inv_stock_out/getserialno/`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getStockInOut(
  searchKey,
  startDate,
  endDate,
  page,
  pageSize
) {
  try {
    const response = await api.get(
      // search=${searchKey || ""}&
      `inv_stock_in?search=${searchKey || ""}&startDate=${
        startDate || ""
      }&endDate=${endDate || ""}&page=${page}&pageSize=${pageSize}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getSingleStockOut(id) {
  try {
    const response = await api.get(
      // search=${searchKey || ""}&
      `inv_stock_out/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getSingleStockIn(id) {
  try {
    const response = await api.get(
      // search=${searchKey || ""}&
      `inv_stock_in/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getBatchesOfSelectedItem(item_id) {
  try {
    const response = await api.get(`inv_item_stock?item_id=${item_id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
