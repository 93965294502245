import React from "react";

const ExitIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2H8C8.55 2 9 1.55 9 1C9 0.45 8.55 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H8C8.55 18 9 17.55 9 17C9 16.45 8.55 16 8 16H2V2Z"
        fill="#ffffff"
      />
      <path
        d="M15.8562 8.681L13.0168 6.13812C12.9461 6.073 12.8552 6.02828 12.7559 6.00967C12.6565 5.99107 12.5533 5.99943 12.4593 6.03368C12.3653 6.06794 12.2849 6.12654 12.2283 6.20197C12.1717 6.2774 12.1415 6.36624 12.1416 6.45712V8.08858H5.0177C4.45797 8.08858 4 8.49872 4 9C4 9.50129 4.45797 9.91143 5.0177 9.91143H12.1416V11.5429C12.1416 11.953 12.6912 12.1535 13.0067 11.8619L15.846 9.319C16.0496 9.14583 16.0496 8.85418 15.8562 8.681Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default ExitIcon;
