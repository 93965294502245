import React from "react";

const BellIcon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="21.5" cy="21.5" r="21.5" fill="#DE4AC4" />
      <path
        d="M30.7844 26.8298C30.2351 25.8338 29.4185 23.0156 29.4185 19.3348C29.4185 17.1243 28.5843 15.0043 27.0994 13.4412C25.6145 11.8781 23.6005 11 21.5005 11C19.4005 11 17.3865 11.8781 15.9016 13.4412C14.4167 15.0043 13.5825 17.1243 13.5825 19.3348C13.5825 23.0167 12.7649 25.8338 12.2156 26.8298C12.0753 27.083 12.001 27.3707 12 27.6638C11.9991 27.9569 12.0715 28.2451 12.2102 28.4994C12.3488 28.7536 12.5486 28.9648 12.7896 29.1118C13.0305 29.2588 13.304 29.3363 13.5825 29.3365H17.6216C17.8043 30.2775 18.2902 31.1231 18.9969 31.7304C19.7037 32.3378 20.5881 32.6695 21.5005 32.6695C22.4129 32.6695 23.2973 32.3378 24.004 31.7304C24.7108 31.1231 25.1967 30.2775 25.3793 29.3365H29.4185C29.6969 29.3361 29.9703 29.2585 30.2111 29.1114C30.4519 28.9644 30.6516 28.7531 30.7901 28.4989C30.9286 28.2447 31.001 27.9566 31 27.6636C30.999 27.3705 30.9246 27.083 30.7844 26.8298ZM21.5005 31.0035C21.0094 31.0033 20.5304 30.8429 20.1295 30.5444C19.7285 30.2459 19.4254 29.8239 19.2617 29.3365H23.7393C23.5756 29.8239 23.2724 30.2459 22.8715 30.5444C22.4706 30.8429 21.9916 31.0033 21.5005 31.0035Z"
        fill="#DE4AC4"
      />
    </svg>
  );
};

export default BellIcon;
