import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanupUserSettings,
  closeProfileModal,
  openProfileModal,
} from "../../../store/slice/auth-slice";
import { getLocationName } from "../../../utils/windowname";
import { getAllChildren } from "../../../utils/routes_childs";
import { useEffect, useRef, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { RiFullscreenFill } from "react-icons/ri";
import ProfileModal from "../../../pages/Profile";
import Hamburger from "../../icons/Hamburger";
import ProfileIcon_mob from "../../icons/ProfileIcon_mob";
import LogoutIcon from "../../icons/Logout";

const Header = ({ handleMobSideBar }) => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const formattedPathname = getLocationName(location);
  const additionalBackButtonPaths = getAllChildren();
  const [showLogout, setShowLogout] = useState(false);
  const logoutButtonRef = useRef(null);
  const currentUser = useSelector((state) => state.auth);

  const handleSHowProfile = () => {
    if (currentUser.isOpen) {
      dispatch(closeProfileModal());
    } else {
      dispatch(openProfileModal());
    }
  };

  useEffect(() => {
    // Function to handle click events outside the logout button container
    function handleClickOutside(event) {
      if (
        logoutButtonRef.current &&
        !logoutButtonRef.current.contains(event.target)
      ) {
        setShowLogout(false);
      }
    }
    // Add event listener to the document body
    document.body.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogoutButtonClick = (event) => {
    event.stopPropagation(); // Prevent click event from propagating to document body
    setShowLogout(!showLogout);
  };

  const handleLogout = () => {
    dispatch(cleanupUserSettings());
    navigation("/login");
  };

  const goBackHandler = () => {
    navigation(-1);
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreenToggle = () => {
    const element = document.documentElement; // Get the root element of the document

    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleFullScreenChange = () => {
    setIsFullScreen(
      !!(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      )
    );
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("msfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullScreenChange
      );
    };
  }, []);

  return (
    <header
      style={{ zIndex: 99 }}
      className={`lg:sticky top-0 flex w-full max-h-[75px] bg-[#EDF6F9] shadow-sm`}
    >
      <div className="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
        <div
          className="hover:cursor-pointer block md:hidden pt-2   md:w-full "
          onClick={handleMobSideBar}
        >
          {/* <img src="/icons/hamburger.svg" className="" alt="" /> */}
          <Hamburger />
        </div>
        {/* Back buttion */}
        {(pathname?.split("/").length > 2 ||
          additionalBackButtonPaths.includes(pathname)) && (
          <button
            onClick={goBackHandler}
            className="flex p-3 justify-center items-center gap-3 lg:w-[86px] lg:mx-0 mx-3 lg:h-[45px] w-[50px] h-[35px] bg-bluishgrey bg-opacity-[60%] shadow-card rounded-15 hover:scale-01"
          >
            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.337232 8.60567C0.121631 8.38689 0.000513077 8.09019 0.000513077 7.78084C0.000513077 7.47148 0.121631 7.17479 0.337232 6.956L6.84305 0.35617C6.94914 0.244741 7.07604 0.155862 7.21635 0.094718C7.35666 0.0335742 7.50757 0.00139017 7.66027 4.40491e-05C7.81297 -0.00130207 7.96441 0.0282169 8.10575 0.0868779C8.24708 0.145539 8.37549 0.232168 8.48347 0.341709C8.59145 0.451251 8.67685 0.581511 8.73467 0.72489C8.7925 0.868269 8.8216 1.0219 8.82027 1.1768C8.81894 1.33171 8.78722 1.4848 8.72694 1.62714C8.66667 1.76948 8.57906 1.89822 8.46922 2.00584L2.77648 7.78084L8.46922 13.5558C8.67871 13.7759 8.79463 14.0706 8.79201 14.3765C8.78939 14.6824 8.66844 14.975 8.45521 15.1913C8.24198 15.4076 7.95353 15.5303 7.65199 15.533C7.35046 15.5356 7.05995 15.418 6.84305 15.2055L0.337232 8.60567Z"
                fill="#DE4AC4"
              />
            </svg>

            <p className="text-bodyRB text-darkgrey hidden lg:block">Back</p>
          </button>
        )}
        {/* Back buttion end */}

        {/* Window name */}
        <h1 className="w-full flex justify-center md:ml-[15%] text-headingBB  text-darkgrey capitalize ">
          {formattedPathname ? formattedPathname : "dashboard"}
        </h1>
        {/* Window name end */}

        {/* Refresh and Logout */}
        <div className="flex items-center">
          <button
            onClick={handleSHowProfile}
            className="hover:scale-105 w-8 h-8 lg:w-[57px] lg:h-[57px]"
          >
            <div className="hidden md:block">
              <img src="/icons/Profile.svg" />
            </div>
            <div className="block md:hidden -mt-1">
              <ProfileIcon_mob />
            </div>
          </button>

          <div
            onClick={handleFullScreenToggle}
            className="hover:scale-105  lg:text-[24px] text-[16px] text-pink bg-bluishgrey bg-opacity-[10%]  w-[22px] h-[22px] lg:w-[40px] lg:h-[42px]  items-center justify-center  rounded-full shadow-card  mr-2 hidden md:flex"
          >
            <RiFullscreenFill />
          </div>

          <button
            className="hover:scale-105 w-8 h-8 lg:w-[57px] lg:h-[57px] hidden md:block"
            onClick={() => {
              window.location.reload();
            }}
          >
            <svg
              // width="57"
              // height="57"
              viewBox="0 0 57 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6" filter="url(#filter0_d_1183_2743)">
                <circle cx="26.5" cy="26.5" r="22.5" fill="#EDF6F9" />
              </g>
              <path
                d="M34.9288 27.6094C34.7282 29.1394 34.1148 30.586 33.1545 31.7939C32.1942 33.0019 30.9232 33.9256 29.4778 34.4661C28.0324 35.0066 26.4672 35.1435 24.9499 34.862C23.4327 34.5805 22.0207 33.8913 20.8653 32.8683C19.71 31.8453 18.8549 30.5272 18.3918 29.0552C17.9287 27.5831 17.875 26.0128 18.2366 24.5126C18.5981 23.0124 19.3612 21.639 20.444 20.5395C21.5267 19.44 22.8884 18.656 24.3829 18.2716C26.9186 17.6212 29.5089 18.1678 31.5 19.601"
                stroke="#DE4AC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M35.0003 18.0029V23.3157H29.6875" fill="#E6F2F7" />
              <path
                d="M35.0003 18.0029V23.3157H29.6875"
                stroke="#DE4AC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <filter
                  id="filter0_d_1183_2743"
                  x="0"
                  y="0"
                  width="57"
                  height="57"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="2" dy="2" />
                  <feGaussianBlur stdDeviation="3" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1183_2743"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1183_2743"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </button>

          <button
            className="hover:scale-105 w-8 h-8 lg:w-[57px] lg:h-[57px] hidden md:block"
            // onClick={handleLogout}
            onClick={handleLogoutButtonClick}
          >
            <LogoutIcon />
          </button>
          {showLogout && (
            <div
              ref={logoutButtonRef} // This ref is used to determine if a click is inside or outside the container
              className="
             bg-[#E9EFFB] flex min-w-[100px] min-h-[35px] sm:min-w-[110px]  justify-between
             items-center text-[#100D2C] -mx-10 lg:mx-10 shadow-card rounded-md p-3 absolute top-full  
             pl-5 pr-5 font-sans text-sm font-semibold hover:cursor-pointer "
            >
              <div className="">
                <div className="flex justify-center h-10 cursor-default max-w-[150px] overflow-hidden">
                  <div className="overflow-hidden overflow-ellipsis">
                    {currentUser.displayName}
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleLogout();
                  }}
                  className="flex items-center justify-between hover:scale-105"
                >
                  <FiLogOut className="font-bold" />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Refresh and Logout end*/}

        <ProfileModal />
      </div>
    </header>
  );
};

export default Header;
