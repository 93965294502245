import React from "react";

const ArrowDownwards = ({ className }) => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.07442 9.0573C8.85564 9.2729 8.55894 9.39402 8.24959 9.39402C7.94023 9.39402 7.64354 9.2729 7.42475 9.0573L0.82492 2.55148C0.713491 2.44539 0.624612 2.31849 0.563468 2.17818C0.502324 2.03787 0.47014 1.88696 0.468794 1.73426C0.467448 1.58156 0.496967 1.43012 0.555628 1.28878C0.614289 1.14745 0.700918 1.01904 0.810459 0.91106C0.920001 0.803079 1.05026 0.717684 1.19364 0.659859C1.33702 0.602033 1.49065 0.572935 1.64555 0.574262C1.80046 0.575589 1.95355 0.607315 2.09589 0.667587C2.23823 0.72786 2.36697 0.815473 2.47459 0.925314L8.24959 6.61805L14.0246 0.925314C14.2446 0.715824 14.5393 0.599906 14.8452 0.602526C15.1511 0.605146 15.4437 0.726095 15.66 0.939323C15.8764 1.15255 15.999 1.441 16.0017 1.74254C16.0044 2.04408 15.8868 2.33458 15.6743 2.55148L9.07442 9.0573Z"
        fill="#DE4AC4"
      />
    </svg>
  );
};

export default ArrowDownwards;
