import React, { useEffect } from "react";
import Dashboard from "../../components/ui/Dashboard";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigation = useNavigate();
  useEffect(() => {
    navigation("/appointments");
  }, []);
  return <Dashboard />;
};

export default Home;
