import React from "react";

const ExportFile = () => {
  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.875 31.25C22.7038 31.25 23.4987 30.9208 24.0847 30.3347C24.6708 29.7487 25 28.9538 25 28.125V20.3125L17.1875 26.5625V21.875H6.25V18.75H17.1875V14.0625L25 20.3125V9.375L15.625 0H3.125C2.2962 0 1.50134 0.32924 0.915291 0.915291C0.32924 1.50134 0 2.2962 0 3.125V28.125C0 28.9538 0.32924 29.7487 0.915291 30.3347C1.50134 30.9208 2.2962 31.25 3.125 31.25H21.875ZM14.0625 3.125L21.875 10.9375H14.0625V3.125Z"
        fill="#DE4AC4"
      />
    </svg>
  );
};

export default ExportFile;
