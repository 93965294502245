import React from "react";

const Arrow = ({ className }) => {
  return (
    <svg
      className={className}
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="#DE4AC4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.6562" r="17.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0573 16.1795C22.2729 16.3983 22.394 16.695 22.394 17.0043C22.394 17.3137 22.2729 17.6104 22.0573 17.8292L15.5515 24.429C15.4454 24.5404 15.3185 24.6293 15.1782 24.6904C15.0379 24.7516 14.887 24.7838 14.7343 24.7851C14.5816 24.7865 14.4301 24.7569 14.2888 24.6983C14.1474 24.6396 14.019 24.553 13.9111 24.4434C13.8031 24.3339 13.7177 24.2036 13.6599 24.0603C13.602 23.9169 13.5729 23.7633 13.5743 23.6084C13.5756 23.4534 13.6073 23.3004 13.6676 23.158C13.7279 23.0157 13.8155 22.8869 13.9253 22.7793L19.618 17.0043L13.9253 11.2293C13.7158 11.0093 13.5999 10.7146 13.6025 10.4087C13.6051 10.1028 13.7261 9.81017 13.9393 9.59386C14.1526 9.37755 14.441 9.25486 14.7425 9.2522C15.0441 9.24954 15.3346 9.36713 15.5515 9.57965L22.0573 16.1795Z"
      />
    </svg>
  );
};

export default Arrow;
