import React from 'react'

const ProfileIcon_mob = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2101_8489)">
    <rect x="4" y="4" width="42" height="42" rx="10" fill="white"/>
    </g>
    <path d="M25.9991 24.6166C23.6238 24.6166 21.6913 22.6838 21.6913 20.308C21.6913 17.9325 23.6238 16 25.9991 16C28.3747 16 30.3074 17.9326 30.3074 20.308C30.3074 22.6838 28.3747 24.6166 25.9991 24.6166Z" fill="#DE4AC4"/>
    <path d="M28.6921 34H23.3079C20.9325 34 19 32.0674 19 29.6919C19 27.3164 20.9325 25.3838 23.3079 25.3838H28.6921C31.0675 25.3838 33 27.3164 33 29.6919C33 32.0674 31.0675 34 28.6921 34Z" fill="#DE4AC4"/>
    <defs>
    <filter id="filter0_d_2101_8489" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feGaussianBlur stdDeviation="4"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.305882 0 0 0 0 0.352941 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2101_8489"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2101_8489" result="shape"/>
    </filter>
    </defs>
    </svg>
    
  )
}

export default ProfileIcon_mob