import React from "react";

const RedX = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.18634 14.0088C1.99569 14.225 1.72448 14.3736 1.42153 14.4279C1.11858 14.4822 0.803794 14.4385 0.533816 14.3049C0.31333 14.2036 0.14387 14.0322 0.0592027 13.8248C-0.0254645 13.6174 -0.0191218 13.3893 0.0769672 13.1859C1.06061 11.4532 2.42846 9.42675 3.3929 8.055C3.88021 7.36196 4.05242 7.23637 3.51052 6.60259C3.05975 6.07534 2.46149 5.42835 2.07771 5.01536C1.41115 4.29832 0.448724 3.3099 0.194122 2.77969C0.0883203 2.51609 0.0801573 2.22933 0.170826 1.96132C0.261495 1.69331 0.446241 1.45811 0.698052 1.29011C1.21696 0.951336 1.6979 1.06575 2.28814 1.58602C2.67103 1.92352 4.90853 4.45369 5.21671 4.81271C5.58876 5.24621 5.68229 5.23492 6.07599 4.75039C6.47584 4.2582 8.06867 2.44582 8.47848 1.96016C9.21499 1.08724 10.0549 0.730161 10.5828 1.18076C10.708 1.2674 10.8127 1.37517 10.8909 1.49788C10.9691 1.62059 11.0193 1.75582 11.0385 1.89581C11.0578 2.0358 11.0457 2.17778 11.003 2.31362C10.9603 2.44945 10.8879 2.57645 10.7899 2.68732C10.0595 3.70732 9.16251 4.63162 8.35179 5.60059C8.12507 5.84188 7.91708 6.0966 7.72927 6.363C7.32714 7.00219 7.28394 7.02967 7.70371 7.67021C7.99552 8.11549 8.52615 8.72771 8.83083 9.11299C9.67138 10.1763 10.5828 11.3435 11.5144 12.3421C12.3113 13.1963 11.5096 13.898 11.0588 14.1049C10.7885 14.2453 10.4672 14.2862 10.1635 14.2187C9.85974 14.1512 9.59778 13.9808 9.43361 13.7439C8.88838 13.1008 8.44123 12.3852 7.93376 11.7178C7.51931 11.1727 7.01137 10.3528 6.56916 9.82132C5.89656 9.0129 5.76878 8.71627 5.48034 9.17186C4.4802 10.7517 2.73301 13.3521 2.18634 14.0088Z"
        fill="#E45353"
      />
    </svg>
  );
};

export default RedX;
