import { api } from "../../config/axios/interceptor";
export const clinic_signup = async (payload) => {
  const response = await api.post(`company`, payload);
  return response;
};

export const sendSignupOtp = async (phone) => {
  const response = await api.post(`/auth/sendSignUpOtp?phone=${phone}`);
  return response;
};

export const resendSignupOtp = async (otp, phone) => {
  const response = await api.post(`/auth/resendSignUpOtp?phone=${phone}`);
  return response;
};
