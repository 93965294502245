export const generateAuthenticationDetails = () => {
  const now = new Date();

  // Get date components
  const year = now.getFullYear();
  const date = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();

  // Generate timestamp in milliseconds
  const timestamp = now.getTime();

  // Generate security code
  const securityCode =
    year * second + date * 5 + hour * 15 + minute * second + second * 3;

  return { timestamp, securityCode };
};
