import React from "react";

const Tick = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.46311 17.6957C8.63506 16.8983 7.96616 16.1183 7.32002 15.3629C6.7296 14.6761 6.21765 13.9421 5.79126 13.1711C5.52439 12.7034 5.40626 12.2297 5.98433 11.9252C7.64101 11.0525 7.98051 11.8858 8.88097 12.8526C9.41472 13.4255 10.2018 14.3702 10.6949 14.9671C11.166 15.5371 11.7403 14.5083 11.9695 14.218C12.7843 13.1863 14.902 10.6606 15.7772 9.72703C16.608 8.84075 19.2896 6.35788 19.7895 5.96296C20.215 5.6267 21.1134 4.88915 21.7176 5.29603C22.3524 5.72353 22.6626 6.53615 22.2163 7.06453C21.4391 7.9844 20.2184 8.85946 19.3737 9.73535C17.6639 11.5084 16.0451 13.4195 14.4912 15.3224C13.9632 15.9691 13.2218 17.0521 12.7616 17.7534C11.9006 19.0653 11.1444 19.315 9.46311 17.6957Z"
        fill="#6AB483"
      />
    </svg>
  );
};

export default Tick;
